import React from 'react'
import {RichText, Link } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'

import IconChevronDown from '../images/icon-chevron.svg'

import './faqs.scss'

function accordionToggle(e){

  var prevActive = "";

  if(e.target.parentElement.classList.contains('kb__faq--active') || e.target.parentElement.parentElement.classList.contains('kb__faq--active')){
    prevActive = document.querySelector('.kb__faq--active');

    if(prevActive){
      prevActive.classList.remove('kb__faq--active');
    }

  }else{
    prevActive = document.querySelector('.kb__faq--active');

    if(prevActive){
      prevActive.classList.remove('kb__faq--active');
    }
    
    if(e.target.parentElement.classList.contains('kb__faq-question')){
      e.target.parentElement.parentElement.classList.add('kb__faq--active')
    }else{
      e.target.parentElement.classList.add('kb__faq--active')
    }
  }
}

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const FAQs = (props) => {
  
  if(!props) return null;
  if(!props.faqs) return null;

  return (
    <section className="kb__faqs">
      <div className="kb__container">
        <div className="kb__banner-content">
          <div className="kb__grid-center">
            
            <div className="kb__col-8_sm-12">
              <div className="kb__faqs-blurb">
                {props.faqs.primary.faq_section_title && 
                  RichText.render(props.faqs.primary.faq_section_title)
                }
                {props.faqs.primary.faqs_section_title && 
                  <h3>{RichText.asText(props.faqs.primary.faqs_section_title)}</h3>
                }
              </div>
            

              {props.faqs.fields &&

                props.faqs.fields.map((faq,i) => {

                  return(

                    <div key={i} className="kb__faq" data-faq-id={i}>
                      <button role="tab" className="kb__faq-question" tabIndex="0" onClick={accordionToggle} data-faq-toggle-id={i}>
                        {RichText.render(faq.faq_question)}
                        <img src={IconChevronDown} alt="Arrow down" aria-hidden="true" />
                      </button>

                      <div className="kb__faq-answer">
                      {RichText.render(faq.faq_answer)}
                      </div>
                    </div>
                    
                  )
                })
              }

            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQs
