import React from 'react'
import { Link } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'

import Shapes from './shapes'

import './banner.scss'

function scrollToNext(e){
  var distanceToScroll = document.querySelector('.kb__banner').offsetHeight;
  
  window.scrollTo({
    top: distanceToScroll,
    left: 0,
    behavior: 'smooth'
  });
}

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const Banner = (props) => {
  
  if(!props.banner) return null;

  var centerLayout
  var bannerCol

  if(!props.banner.center_layout){
    centerLayout = false
    bannerCol = "kb__col-10_sm-12"
  } else {
    centerLayout = props.banner.center_layout
    bannerCol = "kb__col-9_sm-12"
  }

  var sameUrl = false;
  var handledLink;

  if(props.banner.cta_link && props.banner.cta_link._meta){

    if(props.banner.cta_link._meta.__typename !== "PRISMIC__ExternalLink"){
      if(props.banner.cta_link._meta.uid === props.uid){
        sameUrl = true;
      }
    }else{
      sameUrl = false;
    }
  }

  if (props.banner.cta_internal_id){

    if (props.banner.cta_internal_id && sameUrl){
      handledLink = '#'+props.banner.cta_internal_id;
    }else if(props.banner.cta_internal_id && !sameUrl){
      handledLink = RichLink(props.banner.cta_link)+'#'+props.banner.cta_internal_id;
    }else if(!props.banner.cta_internal_id && !sameUrl){
      handledLink = RichLink(props.banner.cta_link);
    }else{
      return null;
    }

  }else{
    if(props.banner.cta_link){
      handledLink = RichLink(props.banner.cta_link);
    }else{
      handledLink = false;
    }
  }
  

  return (
    <section className="kb__banner" data-banner-id={props.uid}>
      <div className="kb__container">
        <div className="kb__banner-content" data-center-layout={centerLayout}>
          <div className="kb__grid-center">
            <div className={bannerCol}>

              <h1>
                {props.banner.heading_part_1 &&
                  props.banner.heading_part_1
                }
                  
                {props.banner.heading_highlight &&  
                  <span className="script">{props.banner.heading_highlight}</span> 
                }

                {props.banner.heading_part_2 &&
                  props.banner.heading_part_2
                }
              </h1>

              {props.banner.sub_heading && 
                <h3>{props.banner.sub_heading}</h3> 
              }

              {props.banner.cta_text &&

                <a href={handledLink} className="kb__button--primary kb__button--large">
                  {props.banner.cta_text}
                </a>
              }
                
            </div>
          </div>
        </div>
      </div>

      {props.banner.show_slide_arrow &&
        <div className="kb__banner-scroller">
          <button onClick={scrollToNext} className="kb__button--circle">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.8789 8.53749L18.8039 9.45471C19.1956 9.84309 19.1956 10.4711 18.8039 10.8553L10.7081 18.8873C10.3164 19.2757 9.68307 19.2757 9.29557 18.8873L1.19557 10.8553C0.803906 10.467 0.803906 9.83896 1.19557 9.45471L2.12057 8.53749C2.51641 8.14498 3.16224 8.15324 3.54974 8.55401L8.33307 13.5327V1.65828C8.33307 1.10877 8.77891 0.666687 9.33307 0.666687H10.6664C11.2206 0.666687 11.6664 1.10877 11.6664 1.65828V13.5327L16.4497 8.55401C16.8372 8.14911 17.4831 8.14085 17.8789 8.53749Z" fill="#3A3A3A"/>
            </svg>
          </button>
        </div>
      }

      <Shapes page={props.uid} />
    </section>
  )
}

export default Banner
