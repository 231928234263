import React from "react"
import { graphql } from "gatsby"
import {RichText } from 'prismic-reactjs'
import Layout from '../components/layout'
import SEO from '../components/seo'

import Banner from '../components/banner'
import PricingTabs from '../components/pricingTabs'


const PricingPage = ({ data }) => {

  if (!data) return null;
  if (!data.prismic) return null;
  if (!data.prismic.allPricing_pages) return null;
  if (!data.prismic.allPricing_pages.edges[0]) return null;
  if (!data.prismic.allPricing_pages.edges[0].node) return null;

  const doc = data.prismic.allPricing_pages.edges[0].node


  return(
    <Layout>

    <SEO 
      title={RichText.asText(doc.meta_title)}
      description={RichText.asText(doc.meta_description)}
      meta={[
        {
          property: `og:image`,
          doc: doc.meta_image.facebook.url
        },{
          property: `twitter:image`,
          doc: doc.meta_image.twitter.url
        }
      ]}
    />
    

      {doc.body.map((slice,i) => {
          if(slice.type === "banner"){
            return(
              <Banner key={i} banner={slice.primary} />
            )
          } else if(slice.type === "pricing_tabs"){
            return(
              <PricingTabs key={i} tabs={slice} />
            )
          } else{
            return null;
          }
      })
      }

    </Layout>
  )
}

export const query = graphql`
{
  prismic {
    allPricing_pages {
      edges {
        node {
          title
          meta_title
          meta_description
          meta_image
          _linkType
          body {
            ... on PRISMIC_Pricing_pageBodyBanner {
              type
              label
              primary {
                center_layout
                cta_internal_id
                heading_highlight
                heading_part_1
                heading_part_2
                sub_heading
                show_slide_arrow
              }
            }
            ... on PRISMIC_Pricing_pageBodyPricing_tabs {
              type
              label
              primary {
                pricing_tabs_title
              }
              fields {
                pricing_tab {
                  _linkType
                  ... on PRISMIC_Pricing {
                    pricing_name
                    pricing_title
                    _linkType
                    _meta {
                      id
                      uid
                      type
                      tags
                    }
                    pricing_sub_title
                    price_cta_text
                    price_cta {
                      _linkType
                      ... on PRISMIC__ExternalLink {
                        target
                        _linkType
                        url
                      }
                    }
                    price_2_title
                    price_2_sub_title
                    price_1_title
                    price_1_sub_title
                    body {
                      ... on PRISMIC_PricingBodyCheckmark_list {
                        type
                        label
                        fields {
                          list_text
                        }
                        primary {
                          list_image
                        }
                      }
                      ... on PRISMIC_PricingBodyFaqs {
                        type
                        label
                        fields {
                          faq_answer
                          faq_question
                        }
                        primary {
                          faqs_section_title
                        }
                      }
                      ... on PRISMIC_PricingBodyFine_print {
                        type
                        label
                        primary {
                          text
                        }
                      }
                      ... on PRISMIC_PricingBodyPrice_tiers___calc {
                        type
                        label
                        fields {
                          additional_price
                          base_price
                          additional_text
                          base_unit
                          tier_copy
                          tier_name
                          tier_plus_1
                          tier_recommendation
                          tier_plus_2
                        }
                        primary {
                          has_calculator
                          tier_banner_text
                          tiers_cta_text
                          tiers_cta_title
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export default PricingPage

